import * as Diff from 'diff'
import { Show, createSignal, onMount } from 'solid-js'
import { TranslationEntryProps } from '~/components/translation-entry/translation-entry.interfaces'
import styles from '~/components/translation-entry/translation-entry.module.scss'
import { deleteTranslation, highlightTranslation, translations, updateTranslation } from '~/services/current-document/translations'
import Comments from '~/components/comments/comments'
import DuplicateDownIcon from '~/assets/icons/arrow-down.svg'
import DeleteIcon from '~/assets/icons/delete.svg'
import OnoIcon from '~/assets/icons/onomatopoeia.svg'
import { translations as translationsDict } from '~/translations'

const TranslationEntry = ({ id, isProofreading=false }: TranslationEntryProps) => {
  const entry = () => translations().find(translation => translation.id === id)
  const index = () => translations().findIndex(translation => translation.id === id)

  // Text Content & Diff
  const lastVersion = () => {
    const versions = entry()?.versions
    const lastVersion = versions ? versions[versions.length - 1] : undefined
    return lastVersion
  }
  const lastTranslation = () => {
    return entry()?.versions.toReversed().find(entry => !entry.isCorrection)
  }
  const [translatedText, setTranslatedText] = createSignal(lastVersion()?.translatedText)
  const showDiff = () => {
    const hasTextDiff = lastTranslation()?.translatedText !== translatedText()
    const isCorrection = lastVersion()?.isCorrection
    return (hasTextDiff && isProofreading) || isCorrection
  }

  const textDiff = () => {
    const original = lastTranslation()?.translatedText ?? ''
    const updated = translatedText() ?? ''
    const diff = Diff.diffWords(original, updated)
    const result: Node[] = []

    diff.forEach(entry => {
      if (!entry.removed) {
        const span = document.createElement('span')
        span.appendChild(document.createTextNode(entry.value))
        if (entry.added) {
          span.style.color = 'red'
        } else {
          span.style.opacity = '0'
        }
        span.innerHTML = span.innerHTML.replaceAll('\n', '<br/>')
        result.push(span)
      }
    })
    return result
  }

  // Event Listeners
  const onChange = () => {
    const newOriginalText = entry()?.originalTextRef
    const newTranslatedText = entry()?.translatedTextRef
    if(newOriginalText && newTranslatedText){
      newOriginalText.value = newOriginalText.value.trim()
      newTranslatedText.value = newTranslatedText.value.trim()
      updateTranslation(id, {
        originalText: newOriginalText.value,
        translatedText: newTranslatedText.value
      })
      resizeTextarea()
    }
  }

  const onInput = () => {
    resizeTextarea()
  }

  const onFocus = (translationField?: boolean) => () => {
    const currentEntry = entry()
    if (currentEntry && !currentEntry.skipFocusEvent) {
      currentEntry.skipFocusEvent = false
      currentEntry.page.document.hasFocus = false
      highlightTranslation(currentEntry, { scrollView: 'canvas', focusField: translationField })
    }
  }

  const onBlur = () => {
    const currentEntry = entry()
    if(currentEntry){
      currentEntry.skipFocusEvent = false
    }
  }

  const onCopy = () => {
    const originalText = entry()?.originalTextRef?.value
    const translatedTextRef = entry()?.translatedTextRef
    if (originalText && translatedTextRef) {
      translatedTextRef.value = originalText
      onChange()
    }
  }

  const onToggleOnomatopoeia = () => {
    const newValue = !lastVersion()?.isOnomatopoeia
    updateTranslation(id, {
      isOnomatopoeia: newValue
    })
  }

  const onKeyDown = (translationField?: boolean) => (event: KeyboardEvent) => {
    if (!event.shiftKey && event.key === "Tab" && translationField) {
      event.preventDefault()
      onNextEntry()
    }
    if (event.shiftKey && event.key === "Tab" && translationField) {
      event.preventDefault()
      onPrevEntry()
    }
  }

  const onKeyUp = () => {
    setTranslatedText(entry()?.translatedTextRef?.value)
  }

  const onPrevEntry = () => {
    const prevIndex = index() - 1
    if (prevIndex >= 0){
      const prevEntry = translations()[prevIndex]
      prevEntry.translatedTextRef?.focus()
    }
  }

  const onNextEntry = () => {
    const nextIndex = index() + 1
    if (nextIndex < translations().length) {
      const nextEntry = translations()[nextIndex]
      nextEntry.translatedTextRef?.focus()
    }
  }

  const onDelete = () => {
    const translation = entry()
    if(translation){
      deleteTranslation(translation, {
        database: true,
        canvas: true,
        history: true,
        view: true
      })
    }
  }

  // Ref Setters
  const onSideviewElementReady = (element: HTMLDivElement) => {
    const translation = entry()
    if (translation) translation['sideviewElementRef'] = element
  }
  const onOriginalTextReady = (element: HTMLTextAreaElement) => {
    const translation = entry()
    if (translation) translation['originalTextRef'] = element
  }
  const onTranslatedTextReady = (element: HTMLTextAreaElement) => {
    const translation = entry()
    if (translation) translation['translatedTextRef'] = element
  }

  // Styles
  const entryStyles = () => ({
    [`${styles.translationEntry}`]: true,
    [`${styles.selected}`]: entry()?.highlighted,
    [`${styles.isOnomatopoeia}`]: lastVersion()?.isOnomatopoeia,
    [`${styles.isCorrection}`]: lastVersion()?.isCorrection
  })

  const diffTextStyles = () => ({
    [`${styles.diffText}`]: true,
    [`${styles.show}`]: showDiff(),
  })

  const onomatopoeiaStyles = () => ({
    [`${styles.onoToggle}`]: true,
    [`${styles.action}`]: true,
    [`${styles.toggled}`]: lastVersion()?.isOnomatopoeia
  })

  const resizeTextarea = () => {
    const originalTextRef = entry()?.originalTextRef
    const translatedTextRef = entry()?.translatedTextRef
    if (originalTextRef && translatedTextRef) {
      originalTextRef.style.height = '0'
      originalTextRef.style.height = originalTextRef.scrollHeight + 'px'
      translatedTextRef.style.height = '0'
      translatedTextRef.style.height = translatedTextRef.scrollHeight + 'px'
    }
  }

  onMount(() => {
    resizeTextarea()
  })

  return (
    <>
      <div classList={entryStyles()} ref={onSideviewElementReady}>
        <div class={styles.head}>
          <div class={styles.index}>
            #{index() + 1}
          </div>
          <div class={styles.actions}>
            <button
              classList={onomatopoeiaStyles()}
              title={translationsDict().editor.translation.entries.markAsOnomatopoeia}
              onClick={onToggleOnomatopoeia}
            >
              <OnoIcon class={styles.icon} />
            </button>
            <button
              class={styles.action}
              title={translationsDict().general.actions.delete}
              onClick={onDelete}
            >
              <DeleteIcon class={styles.icon} />
            </button>
          </div>
        </div>
        <div class={styles.textFields}>
          <textarea
            class={styles.originalText}
            ref={onOriginalTextReady}
            placeholder={translationsDict().editor.translation.entries.originalText}
            onInput={onInput}
            onFocus={onFocus()}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown()}
          >
            {lastVersion()?.originalText}
          </textarea>
          <button class={styles.contextAction} onClick={onCopy}>
            <DuplicateDownIcon class={styles.icon} />
            <span>{translationsDict().general.actions.copy}</span>
          </button>
          <div class={styles.diffContainer}>
            <div classList={diffTextStyles()}>
              {textDiff()}
            </div>
            <textarea
              class={styles.translatedText}
              placeholder={translationsDict().editor.translation.entries.translationText}
              onFocus={onFocus(true)}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={onKeyDown(true)}
              onKeyUp={onKeyUp}
              onInput={onInput}
              ref={onTranslatedTextReady}
            >
              {lastVersion()?.translatedText}
            </textarea>
          </div>
        </div>
        <Show when={entry()}>
          {entry => (
            <Comments entry={entry()} />
          )}
        </Show>
      </div>
    </>
  )
}

export default TranslationEntry