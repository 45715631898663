import { Component, For, Show, createSignal } from 'solid-js'
import TranslationEntry from '~/components/translation-entry/translation-entry'
import { translations } from '~/services/current-document/translations'
import styles from '~/components/translations-list/translations-list.module.scss'
import { setTranslationsList } from '~/services/current-document/translations-list'
import { TranslationsListProps } from '~/components/translations-list/translations-list.interfaces'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import Translation from '~/editor/translation/translation'
import EmptyState from '~/components/empty-state/empty-state'
import EmptyIcon from '~/assets/icons/bubble.svg'
import { translations as translationsDict } from '~/translations'

const TranslationsList: Component<TranslationsListProps> = ({ mode }) => {
  const [filterBy, setFilterBy] = createSignal<PillOption[]>([
    {
      label: translationsDict().editor.translation.filterOptions.all,
      value: 'all',
      checked: true
    },
    {
      label: translationsDict().editor.translation.filterOptions.corrections,
      value: 'corrections',
      checked: false
    },
    {
      label: translationsDict().editor.translation.filterOptions.comments,
      value: 'comments',
      checked: false
    },
    {
      label: translationsDict().editor.translation.filterOptions.onomatopoeias,
      value: 'onomatopoeas',
      checked: false
    }
  ])

  const filterByCorrections = (entry: Translation) => {
    const hasCorrection = entry.versions[entry.versions.length - 1].isCorrection
    return hasCorrection
  }

  const filterByComments = (entry: Translation) => {
    const hasOpenCommentThread = entry.comments.length > 0 && !entry.comments[entry.comments.length - 1].resolved
    return hasOpenCommentThread
  }

  const filterByOnomatopoeas = (entry: Translation) => {
    return entry.versions[entry.versions.length - 1].isOnomatopoeia
  }

  const filteredEntries = () => {
    let entries = translations()
    const currentFilter = filterBy().find(option => option.checked)?.value ?? 'all'
    if(currentFilter === 'corrections') entries = entries.filter(filterByCorrections)
    if (currentFilter === 'comments') entries = entries.filter(filterByComments)
    if (currentFilter === 'onomatopoeas') entries = entries.filter(filterByOnomatopoeas)
    return entries
  }

  const registerRef = (element: HTMLDivElement) => {
    setTranslationsList(element)
  }

  return (
    <>
      <div class={styles.toolbar}>
        <div class={styles.toolEntry}>
          <div class={styles.label}>
            {translationsDict().general.actions.display}
          </div>
          <TogglePill
            options={filterBy()}
            setOptions={setFilterBy}
          />
        </div>
        {/* <div class={styles.toolEntry}>
          <SearchReplace />
        </div> */}
      </div>

      <div class={styles.translationsList} ref={registerRef}>
        <For each={filteredEntries()}>
          {(translation) => (
            <TranslationEntry
              id={translation.id}
              isProofreading={mode === 'proofreading'}
            />
          )}
        </For>
        <Show when={translations().length === 0}>
          <EmptyState
            icon={EmptyIcon}
            label={translationsDict().editor.translation.entries.emptyState.label}
            description={translationsDict().editor.translation.entries.emptyState.description}
          />
        </Show>
        <Show when={translations().length > 0 && filteredEntries().length === 0}>
          <EmptyState
            icon={EmptyIcon}
            label={translationsDict().editor.translation.entries.filteredEmptyState.label}
            description={translationsDict().editor.translation.entries.filteredEmptyState.description}
          />
        </Show>
      </div>
    </>
  )
}

export default TranslationsList